import React, { useEffect, useRef, useCallback } from 'react';
import './MergedTextEditor.css';

const MergedTextEditor = ({
  value,
  onChange,
  onKeyDown,
  placeholder,
  isExternalUpdate,
  isTranscribed,
  isScriptGenerated,
  onGenerateScript,
  isScriptLoading,
  isPersonalizationMode,
  isGlowing,
  onBlur,
  isSessionCreated,
  mode
}) => {
  const textAreaRef = useRef(null);
  const containerRef = useRef(null);

  const cleanText = useCallback((text) => {
    if (!text) return '';
    
    // Preserve spaces while cleaning
    let cleanedText = text
      // Replace SSML tags with a single space
      .replace(/\s*\[PROSODY_START\]\s*|\s*\[PROSODY_END\]\s*|\s*\[PAUSE( LONG)?\]\s*/g, ' ')
      // Fix quotes
      .replace(/``\s*(.*?)\s*''/g, '"$1"')
      .replace(/\s*''\s*/g, '')
      // Preserve multiple spaces during initial cleaning
      .replace(/\s+/g, ' ')
      // Fix punctuation spacing
      .replace(/\s([,.!?])/g, '$1')
      .replace(/([,.!?])(?!\s)/g, '$1 ');

    // Final trim to remove leading/trailing spaces
    return cleanedText.trim();
  }, []);

  // Handle text change with debounce
  const handleTextChange = useCallback((e) => {
    const newValue = e.target.value;
    
    // For Safari compatibility, delay the cleaning
    requestAnimationFrame(() => {
      const cleanedValue = cleanText(newValue);
      onChange({ target: { value: cleanedValue } });
    });
  }, [onChange, cleanText]);

  // Focus and glow effect
  useEffect(() => {
    if (isGlowing && textAreaRef.current) {
      textAreaRef.current.focus();
      containerRef.current?.classList.add('glow-effect');
    } else if (containerRef.current) {
      containerRef.current?.classList.remove('glow-effect');
    }
  }, [isGlowing]);

  // Fade in effect
  useEffect(() => {
    if (isExternalUpdate && textAreaRef.current) {
      textAreaRef.current.classList.add('fade-in');
      const timer = setTimeout(() => {
        textAreaRef.current?.classList.remove('fade-in');
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isExternalUpdate]);

  // Script generated glow effect
  useEffect(() => {
    if (isScriptGenerated && containerRef.current) {
      containerRef.current.classList.add('script-generated-glow');
      const timer = setTimeout(() => {
        containerRef.current?.classList.remove('script-generated-glow');
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [isScriptGenerated]);

  // Prevent default space behavior in Safari
  const handleKeyDown = (e) => {
    if (e.key === ' ' && e.target.selectionStart === e.target.selectionEnd) {
      e.preventDefault();
      const cursorPosition = e.target.selectionStart;
      const currentValue = e.target.value;
      const newValue = 
        currentValue.substring(0, cursorPosition) + 
        ' ' + 
        currentValue.substring(cursorPosition);
      
      e.target.value = newValue;
      
      // Update cursor position
      requestAnimationFrame(() => {
        e.target.selectionStart = cursorPosition + 1;
        e.target.selectionEnd = cursorPosition + 1;
        // Trigger onChange with the new value
        handleTextChange({ target: { value: newValue } });
      });
    }
    
    // Call the original onKeyDown handler
    onKeyDown?.(e);
  };

  return (
    <div
      ref={containerRef}
      className={`text-container
        ${isGlowing ? 'glow-effect' : ''}
        ${isScriptGenerated ? 'script-generated-glow' : ''}
        ${isSessionCreated ? 'session-created' : ''}
        ${mode}`}
    >
      <textarea
        ref={textAreaRef}
        className="text-area"
        placeholder={placeholder}
        value={value}
        onChange={handleTextChange}
        onKeyDown={handleKeyDown}
        onBlur={onBlur}
        spellCheck="true"
        autoCapitalize="sentences"
      />
      {mode === 'prompt' && isPersonalizationMode && !isScriptGenerated && (
        <div className="generate-script-btn-container">
          <button
            className="wave-btn"
            onClick={onGenerateScript}
            disabled={isScriptLoading}
          >
            <span className="wave-effect" />
            <span className="wave-text">Generate Script</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default MergedTextEditor;