import React, { useState, useEffect, useCallback, useRef } from 'react';
import './MusicLibraryDropdown.css';

const MusicLibraryDropdown = ({ onMusicSelect, isTTSLoaded, isSessionCreated }) => {
  const [musicFiles, setMusicFiles] = useState([]);
  const [selectedMusic, setSelectedMusic] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isMusicLoaded, setIsMusicLoaded] = useState(false);
  const abortControllerRef = useRef(null);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  // Debug log for props
  useEffect(() => {
    console.log('MusicLibraryDropdown Props:', {
      isTTSLoaded,
      isSessionCreated,
      musicFilesCount: musicFiles.length,
      selectedMusic,
      isLoading,
      error,
      isMusicLoaded
    });
  }, [isTTSLoaded, isSessionCreated, musicFiles, selectedMusic, isLoading, error, isMusicLoaded]);

  const fetchMusicIndex = useCallback(async () => {
    console.log('Attempting to fetch music index. isTTSLoaded:', isTTSLoaded);
    if (!isTTSLoaded) return;

    setIsLoading(true);
    setError(null);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();

    try {
      console.log('Sending fetch request to:', `${baseUrl}/load-music`);
      const response = await fetch(`${baseUrl}/load-music`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        signal: abortControllerRef.current.signal
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Music files fetched successfully:', data);
      
      if (!Array.isArray(data)) {
        console.warn('Received non-array data:', data);
        setMusicFiles([]);
        return;
      }

      setMusicFiles(data);
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('Error fetching music index:', error);
        setError(`Failed to load music index: ${error.message}`);
      }
    } finally {
      setIsLoading(false);
    }
  }, [isTTSLoaded, baseUrl]);

  useEffect(() => {
    console.log('useEffect triggered. isTTSLoaded:', isTTSLoaded);
    if (isTTSLoaded) {
      fetchMusicIndex();
    }

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [isTTSLoaded, fetchMusicIndex]);

  const loadMusicIntoMixer = useCallback(async (fileName) => {
    console.log('Attempting to load music:', fileName);
    if (!fileName) {
      setError('No music file selected');
      setIsMusicLoaded(false);
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(`${baseUrl}/load-music/${encodeURIComponent(fileName)}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error(`Failed to load music. Status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Music load response:', data);

      if (!data.download_url) {
        throw new Error('No download URL provided');
      }

      onMusicSelect(fileName);
      setSelectedMusic(fileName);
      setIsMusicLoaded(true);
    } catch (error) {
      console.error('Error loading music:', error);
      setError(`Error loading music: ${error.message}`);
      setSelectedMusic('');
      setIsMusicLoaded(false);
    } finally {
      setIsLoading(false);
    }
  }, [onMusicSelect, baseUrl]);

  return (
    <div className="music-library-dropdown-container">
      <div className={`music-library-dropdown ${isSessionCreated ? 'session-created' : ''} ${isMusicLoaded ? 'music-loaded' : ''}`}>
        {isLoading ? (
          <div>Loading music...</div>
        ) : error ? (
          <div className="error">{error}</div>
        ) : (
          <select
            onChange={(e) => loadMusicIntoMixer(e.target.value)}
            value={selectedMusic}
            disabled={!isTTSLoaded || isLoading}
          >
            <option value="">Select Background Music</option>
            {musicFiles.map((file, index) => (
              <option key={index} value={file.name}>
                {file.name}
              </option>
            ))}
          </select>
        )}
      </div>
    </div>
  );
};

export default MusicLibraryDropdown;