import React, { useState, useEffect } from 'react';
import './VoiceSelector.css'; // Make sure this path is correct

function VoiceSelector({ selectedService, onVoiceChange }) {
  const [selectedVoice, setSelectedVoice] = useState('');

  const openaiVoices = [
    { label: "Erika (alloy)", value: "alloy" },
    { label: "Micheal (echo)", value: "echo" },
    { label: "Teal (fable)", value: "fable" },
    { label: "Orson (onyx)", value: "onyx" },
    { label: "Simone (nova)", value: "nova" },
    { label: "Jade (shimmer)", value: "shimmer" },
  ];

  const azureVoices = [
    { label: "Jenny (en-US)", value: "en-US-JennyNeural" },
    { label: "Guy (en-US)", value: "en-US-GuyNeural" },
    { label: "Ryan (en-GB)", value: "en-GB-RyanNeural" },
    { label: "Sonia (en-GB)", value: "en-GB-SoniaNeural" },
    // Add more Azure voices as needed
  ];

  const voices = selectedService === 'azure' ? azureVoices : openaiVoices;

  useEffect(() => {
    // Reset selected voice when the service changes
    setSelectedVoice('');
  }, [selectedService]);

  const handleChange = (event) => {
    setSelectedVoice(event.target.value);
    onVoiceChange(event.target.value);
  };

  return (
    <div className="voice-selector-container">
      <select id="voice-select" value={selectedVoice} onChange={handleChange}>
        <option value="">Select a voice...</option>
        {voices.map((voice) => (
          <option key={voice.value} value={voice.value}>
            {voice.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default VoiceSelector;
