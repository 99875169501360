// Header.js
import React from 'react';
import './Header.css';

const Header = ({ title }) => {
  return (
    <div className="header-container">
      <img 
        src="pauseapp.png" // Make sure this path correctly points to your image file
        alt={title}
        className="header-image"
      />
      {/* If you don't want to display the title, comment out or remove this line */}
      {/* <h1>{title}</h1> */}
    </div>
  );
}

export default Header;