import React, { useState, useCallback, useRef, useEffect } from 'react';
import { IoChevronUpSharp, IoChevronDownSharp } from 'react-icons/io5';
import './slider.css';

// Preset Data
const binauralBeatPresets = [
  { label: "Delta (Deep Sleep)", base_frequency: 100, beat_frequency: 2 },
  { label: "Theta (Meditation)", base_frequency: 150, beat_frequency: 5 },
  { label: "Alpha (Relaxation)", base_frequency: 200, beat_frequency: 10 },
  { label: "Beta (Concentration)", base_frequency: 250, beat_frequency: 20 },
  { label: "Gamma (Cognition)", base_frequency: 300, beat_frequency: 40 },
];

const oceanWavePresets = [
  { label: "Calm Beach", wave_frequency: 0.05, noise_amplitude: 0.2 },
  { label: "Gentle Waves", wave_frequency: 0.1, noise_amplitude: 0.3 },
  { label: "Rolling Waves", wave_frequency: 0.2, noise_amplitude: 0.4 },
  { label: "Stormy Sea", wave_frequency: 0.3, noise_amplitude: 0.5 },
];

const stereoPanPresets = [
  { label: "Slow Pan", value: 50, speed: 30, hold_time: 10, pattern: 'sine' },
  { label: "Fast Pan", value: 70, speed: 10, hold_time: 5, pattern: 'triangle' },
  { label: "Random Pan", value: 60, speed: 20, hold_time: 0, pattern: 'random' },
];

const reverbPresets = [
  { label: "Subtle Echo", value: 10 },
  { label: "Room Space", value: 30 },
  { label: "Deep Space", value: 50 },
];

const getPreset = (filterName, presetLabel) => {
  const presetsMap = {
    binauralBeats: binauralBeatPresets,
    oceanWaves: oceanWavePresets,
    stereoPan: stereoPanPresets,
    reverb: reverbPresets,
  };

  return presetsMap[filterName]?.find(p => p.label === presetLabel) || null;
};

  const VolumeControls = ({
    volume,
    setVolume, // Add this here
    onVolumeChange,
    filters,
    setFilters,
    isPlaying,
    error,
    isTTSLoaded,
    selectedMusic,
    ttsKey,
    debouncedFetchMixedAudio,
    disabled
}) => {
  const [expandedFilter, setExpandedFilter] = useState(null);
  const [pendingFilters, setPendingFilters] = useState(filters);
  const [hasChanges, setHasChanges] = useState(false);
  const debounceTimeoutRef = useRef(null);

  useEffect(() => {
    setPendingFilters(filters);
  }, [filters]);

  useEffect(() => {
    const updateAllVolumeSliders = () => {
      ['narration', 'music', 'overall'].forEach(type => {
        const sliderElement = document.querySelector(`#${type}-slider`);
        if (sliderElement) {
          const percentage = (volume[type] / 100) * 100;
          sliderElement.style.background = `linear-gradient(to right, #a020f0 0%, #a020f0 ${percentage}%, #444 ${percentage}%, #444 100%)`;
        }
      });
    };

    updateAllVolumeSliders();
  }, [volume]);

  const calculateSliderBackground = (value, min, max) => {
    const percentage = ((value - min) / (max - min)) * 100;
    return `linear-gradient(to right, #a020f0 0%, #a020f0 ${percentage}%, #444 ${percentage}%, #444 100%)`;
  };

  const handleSliderChange = (event) => {
    const slider = event.target;
    const sliderValueLabel = slider.nextElementSibling;
    const sliderRect = slider.getBoundingClientRect();
    const thumbWidth = 18;
    const percentage = (slider.value - slider.min) / (slider.max - slider.min);
    const offset = percentage * (sliderRect.width - thumbWidth) + thumbWidth / 2;
    sliderValueLabel.style.left = `${offset}px`;
  };

  const handleVolumeChange = useCallback(
    (type, newValue) => {
      const clampedValue = Math.max(0, Math.min(100, newValue));
  
      // Update volume state immediately
      setVolume(prevVolume => {
        const updatedVolume = {
          ...prevVolume,
          [type]: clampedValue,
        };
  
        // Prepare the volume settings for the API call
        const volumeSettings = {
          bg_volume: updatedVolume.music / 100,
          tts_volume: updatedVolume.narration / 100,
          overall_volume: updatedVolume.overall / 100,
        };
  
        // Fetch mixed audio if required components are loaded
        if (selectedMusic && ttsKey && isTTSLoaded) {
          if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
          }
  
          debounceTimeoutRef.current = setTimeout(() => {
            debouncedFetchMixedAudio(
              volumeSettings,
              selectedMusic,
              filters,
              ttsKey
            );
          }, 300);
        }
  
        return updatedVolume;
      });
    },
    [selectedMusic, ttsKey, isTTSLoaded, filters, debouncedFetchMixedAudio]
  );
  
  

  const handleFilterValueChange = useCallback((filterName, changes) => {
    setPendingFilters(prevFilters => {
      const updatedFilters = {
        ...prevFilters,
        [filterName]: {
          ...prevFilters[filterName],
          ...changes,
        },
      };
      setHasChanges(true);
      return updatedFilters;
    });
  }, []);

  const handlePresetChange = (filterName, presetLabel) => {
    if (disabled) return;

    const preset = getPreset(filterName, presetLabel);

    if (preset) {
      setPendingFilters(prevFilters => ({
        ...prevFilters,
        [filterName]: {
          ...prevFilters[filterName],
          ...preset,
          enabled: true,
          preset: presetLabel,
        },
      }));
      setHasChanges(true);
    }
  };

  const handleCheckboxToggle = (filterName) => {
    if (disabled) return;

    setPendingFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: {
        ...prevFilters[filterName],
        enabled: !prevFilters[filterName]?.enabled,
        ...(filterName === 'oceanWaves' && !prevFilters[filterName]?.enabled && {
          volume: 50,
          wave_frequency: 0.1,
          noise_amplitude: 0.2,
          pan: {
            enabled: false,
            value: 70,
            speed: 20,
            pattern: 'sine',
            hold_time: 10,
          },
        }),
      },
    }));
    setHasChanges(true);
  };

  const applyFilters = () => {
    if (hasChanges) {
      setFilters(pendingFilters);
      if (selectedMusic && ttsKey && isTTSLoaded) {
        const volumeSettings = {
          bg_volume: volume.music / 100,
          tts_volume: volume.narration / 100,
          overall_volume: volume.overall / 100,
        };
        debouncedFetchMixedAudio(volumeSettings, selectedMusic, pendingFilters, ttsKey);
      }
      setHasChanges(false);
    }
  };

  const toggleFilterExpansion = (filterName) => {
    if (disabled) return;
    setExpandedFilter(prev => (prev === filterName ? null : filterName));
  };

  const renderFilterControls = (filterName, filterData) => (
    <div className="filter-controls">
      <div className="control-group">
        <label>Select Preset:</label>
        <select
          value={pendingFilters[filterName]?.preset || ''}
          onChange={(e) => handlePresetChange(filterName, e.target.value)}
          className="filter-select"
          disabled={disabled || !filterData.enabled}
        >
          <option value="" disabled>Select a preset</option>
          {filterName === 'binauralBeats' && binauralBeatPresets.map(preset => (
            <option key={preset.label} value={preset.label}>{preset.label}</option>
          ))}
          {filterName === 'oceanWaves' && oceanWavePresets.map(preset => (
            <option key={preset.label} value={preset.label}>{preset.label}</option>
          ))}
          {filterName === 'stereoPan' && stereoPanPresets.map(preset => (
            <option key={preset.label} value={preset.label}>{preset.label}</option>
          ))}
          {filterName === 'reverb' && reverbPresets.map(preset => (
            <option key={preset.label} value={preset.label}>{preset.label}</option>
          ))}
        </select>
      </div>

      {/* Stereo Pan Filter Controls */}
      {filterName === 'stereoPan' && (
        <>
          <div className="control-group">
            <div className="tooltip-container">
              <label className="label-text">Pan Strength</label>
              <span className="tooltip" title="Controls how far the sound moves between left and right channels. Higher values mean stronger panning effect.">ⓘ</span>
            </div>
            <div className="slider-wrapper">
              <input
                type="range"
                min="0"
                max="100"
                value={pendingFilters[filterName].value}
                onChange={(e) => handleFilterValueChange(filterName, { value: Number(e.target.value) })}
                className="filter-slider"
                disabled={disabled || !filterData.enabled}
                style={{ background: calculateSliderBackground(pendingFilters[filterName].value, 0, 100) }}
              />
              <div className="slider-value">{pendingFilters[filterName].value}%</div>
            </div>
          </div>

          <div className="control-group">
            <div className="tooltip-container">
              <label className="label-text">Speed</label>
              <span className="tooltip" title="Sets the speed of the panning motion.">ⓘ</span>
            </div>
            <div className="slider-wrapper">
              <input
                type="range"
                min="0"
                max="60"
                value={pendingFilters[filterName].speed}
                onChange={(e) => handleFilterValueChange(filterName, { speed: Number(e.target.value) })}
                className="filter-slider"
                disabled={disabled || !filterData.enabled}
                style={{ background: calculateSliderBackground(pendingFilters[filterName].speed, 0, 60) }}
              />
              <div className="slider-value">{pendingFilters[filterName].speed}s</div>
            </div>
          </div>

          <div className="control-group">
            <div className="tooltip-container">
              <label className="label-text">Hold Time</label>
              <span className="tooltip" title="Sets the time the pan effect is held before moving to a new position.">ⓘ</span>
            </div>
            <div className="slider-wrapper">
              <input
                type="range"
                min="0"
                max="60"
                value={pendingFilters[filterName].hold_time}
                onChange={(e) => handleFilterValueChange(filterName, { hold_time: Number(e.target.value) })}
                className="filter-slider"
                disabled={disabled || !filterData.enabled}
                style={{ background: calculateSliderBackground(pendingFilters[filterName].hold_time, 0, 60) }}
              />
              <div className="slider-value">{pendingFilters[filterName].hold_time}s</div>
            </div>
          </div>

          <div className="control-group">
            <div className="tooltip-container">
              <label className="label-text">Pattern</label>
              <span className="tooltip" title="Choose the movement pattern: Sine (smooth wave-like movement), Triangle (sharp and linear), Random (unpredictable shifts), or Hold Center.">ⓘ</span>
            </div>
            <select
              value={pendingFilters[filterName].pattern}
              onChange={(e) => handleFilterValueChange(filterName, { pattern: e.target.value })}
              className="filter-select"
              disabled={disabled || !filterData.enabled}
            >
              <option value="sine">Sine</option>
              <option value="triangle">Triangle</option>
              <option value="square">Square</option>
              <option value="random">Random</option>
              <option value="hold_center">Hold Center</option>
            </select>
          </div>
        </>
      )}

      {/* Binaural Beats Filter Controls */}
      {filterName === 'binauralBeats' && (
        <>
          <div className="control-group">
            <div className="tooltip-container">
              <label className="label-text">Base Frequency</label>
              <span className="tooltip" title="This controls the fundamental tone's frequency for the binaural beat. Lower values create deeper tones.">ⓘ</span>
            </div>
            <div className="slider-wrapper">
              <input
                type="range"
                min="20"
                max="500"
                value={pendingFilters[filterName].base_frequency}
                onChange={(e) => handleFilterValueChange(filterName, { base_frequency: Number(e.target.value) })}
                className="filter-slider"
                disabled={disabled || !filterData.enabled}
                style={{ background: calculateSliderBackground(pendingFilters[filterName].base_frequency, 20, 500) }}
              />
              <div className="slider-value">{pendingFilters[filterName].base_frequency} Hz</div>
            </div>
          </div>

          <div className="control-group">
            <div className="tooltip-container">
              <label className="label-text">Beat Frequency</label>
              <span className="tooltip" title="The frequency difference between the tones played in each ear. It affects brainwave entrainment and meditation depth.">ⓘ</span>
            </div>
            <div className="slider-wrapper">
              <input
                type="range"
                min="0.5"
                max="40"
                step="0.5"
                value={pendingFilters[filterName].beat_frequency}
                onChange={(e) => handleFilterValueChange(filterName, { beat_frequency: Number(e.target.value) })}
                className="filter-slider"
                disabled={disabled || !filterData.enabled}
                style={{ background: calculateSliderBackground(pendingFilters[filterName].beat_frequency, 0.5, 40) }}
              />
              <div className="slider-value">{pendingFilters[filterName].beat_frequency} Hz</div>
            </div>
          </div>

          <div className="control-group">
            <div className="tooltip-container">
              <label className="label-text">Volume</label>
              <span className="tooltip" title="Controls the volume of the binaural beat sound in relation to other audio elements.">ⓘ</span>
            </div>
            <div className="slider-wrapper">
              <input
                type="range"
                min="0"
                max="100"
                value={pendingFilters[filterName]?.volume ?? 50}
                onChange={(e) => handleFilterValueChange(filterName, { volume: Number(e.target.value) })}
                className="filter-slider"
                disabled={disabled || !filterData.enabled}
                style={{ background: calculateSliderBackground(pendingFilters[filterName]?.volume ?? 50, 0, 100) }}
              />
              <div className="slider-value">{pendingFilters[filterName]?.volume ?? 50}%</div>
            </div>
          </div>
        </>
      )}
      {/* Ocean Waves Filter Controls */}
      {filterName === 'oceanWaves' && (
        <>
          <div className="control-group">
            <div className="tooltip-container">
              <label className="label-text">Wave Intensity</label>
              <span className="tooltip" title="Adjusts how powerful or soft the ocean wave sound feels. Higher values increase wave amplitude.">ⓘ</span>
            </div>
            <div className="slider-wrapper">
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={pendingFilters[filterName]?.noise_amplitude ?? 0}
                onChange={(e) => handleFilterValueChange(filterName, { noise_amplitude: Number(e.target.value) })}
                className="filter-slider"
                disabled={disabled || !filterData.enabled}
                style={{ background: calculateSliderBackground(pendingFilters[filterName]?.noise_amplitude ?? 0, 0, 1) }}
              />
              <div className="slider-value">{(pendingFilters[filterName]?.noise_amplitude ?? 0).toFixed(2)}</div>
            </div>
          </div>

          <div className="control-group">
            <div className="tooltip-container">
              <label className="label-text">Wave Speed</label>
              <span className="tooltip" title="Controls the frequency of wave movements, with higher values making waves come in more frequently.">ⓘ</span>
            </div>
            <div className="slider-wrapper">
              <input
                type="range"
                min="0.05"
                max="0.5"
                step="0.01"
                value={pendingFilters[filterName]?.wave_frequency ?? 0}
                onChange={(e) => handleFilterValueChange(filterName, { wave_frequency: Number(e.target.value) })}
                className="filter-slider"
                disabled={disabled || !filterData.enabled}
                style={{ background: calculateSliderBackground(pendingFilters[filterName]?.wave_frequency ?? 0, 0.05, 0.5) }}
              />
              <div className="slider-value">{(pendingFilters[filterName]?.wave_frequency ?? 0).toFixed(2)} Hz</div>
            </div>
          </div>

          <div className="control-group">
            <div className="tooltip-container">
              <label className="label-text">Volume</label>
              <span className="tooltip" title="Controls how loud the ocean waves sound in comparison to the other audio elements.">ⓘ</span>
            </div>
            <div className="slider-wrapper">
              <input
                type="range"
                min="0"
                max="100"
                value={pendingFilters[filterName]?.volume ?? 0}
                onChange={(e) => handleFilterValueChange(filterName, { volume: Number(e.target.value) })}
                className="filter-slider"
                disabled={disabled || !filterData.enabled}
                style={{ background: calculateSliderBackground(pendingFilters[filterName]?.volume ?? 0, 0, 100) }}
              />
              <div className="slider-value">{pendingFilters[filterName]?.volume ?? 0}%</div>
            </div>
          </div>
        </>
      )}

{/* Ocean Waves Pan Controls */}
{filterName === 'oceanWaves' && (
  <div className="control-group ocean-pan-controls">
    <label className="neomorphic-checkbox-label">
      <input
        type="checkbox"
        checked={pendingFilters[filterName].pan?.enabled || false}
        onChange={() => handleFilterValueChange(filterName, {
          pan: {
            ...pendingFilters[filterName].pan,
            enabled: !pendingFilters[filterName].pan?.enabled,
          }
        })}
        disabled={disabled}
        className="neomorphic-checkbox"
      />
      <span className="checkbox-label-text">Enable Pan</span>
    </label>

    {pendingFilters[filterName].pan?.enabled && (
      <>
        <div className="control-group">
          <div className="tooltip-container">
            <label className="label-text">Pan Strength</label>
            <span className="tooltip" title="Controls how far the sound moves between left and right channels. Higher values mean stronger panning effect.">ⓘ</span>
          </div>
          <div className="slider-wrapper">
            <input
              type="range"
              min="0"
              max="100"
              value={pendingFilters[filterName].pan.value}
              onChange={(e) => handleFilterValueChange(filterName, {
                pan: {
                  ...pendingFilters[filterName].pan,
                  value: Number(e.target.value)
                }
              })}
              className="filter-slider"
              disabled={disabled}
              style={{ background: calculateSliderBackground(pendingFilters[filterName].pan.value, 0, 100) }}
            />
            <div className="slider-value">{pendingFilters[filterName].pan.value}%</div>
          </div>
        </div>

        <div className="control-group">
          <div className="tooltip-container">
            <label className="label-text">Pan Speed</label>
            <span className="tooltip" title="Sets the speed at which the pan moves across channels, with higher values leading to faster shifts.">ⓘ</span>
          </div>
          <div className="slider-wrapper">
            <input
              type="range"
              min="0"
              max="60"
              value={pendingFilters[filterName].pan.speed}
              onChange={(e) => handleFilterValueChange(filterName, {
                pan: {
                  ...pendingFilters[filterName].pan,
                  speed: Number(e.target.value)
                }
              })}
              className="filter-slider"
              disabled={disabled}
              style={{ background: calculateSliderBackground(pendingFilters[filterName].pan.speed, 0, 60) }}
            />
            <div className="slider-value">{pendingFilters[filterName].pan.speed} sec</div>
          </div>
        </div>

        <div className="control-group">
          <div className="tooltip-container">
            <label className="label-text">Pan Pattern</label>
            <span className="tooltip" title="Selects the panning pattern, altering the movement style of the sound between channels.">ⓘ</span>
          </div>
          <select
            value={pendingFilters[filterName].pan.pattern}
            onChange={(e) => handleFilterValueChange(filterName, {
              pan: {
                ...pendingFilters[filterName].pan,
                pattern: e.target.value
              }
            })}
            className="filter-select"
            disabled={disabled}
          >
            <option value="sine">Sine</option>
            <option value="triangle">Triangle</option>
            <option value="random">Random</option>
          </select>
        </div>
      </>
    )}
  </div>
)}

      {/* Reverb Filter Controls */}
      {filterName === 'reverb' && (
        <div className="control-group">
          <div className="tooltip-container">
            <label className="label-text">Effect Strength</label>
            <span className="tooltip" title="This slider adjusts how much reverb is applied to the sound, affecting the perceived space.">ⓘ</span>
          </div>
          <div className="slider-wrapper">
            <input
              type="range"
              min="0"
              max="100"
              value={pendingFilters[filterName].value}
              onChange={(e) => handleFilterValueChange(filterName, { value: Number(e.target.value) })}
              className="filter-slider"
              disabled={disabled || !filterData.enabled}
              style={{ background: calculateSliderBackground(pendingFilters[filterName].value, 0, 100) }}
            />
            <div className="slider-value">{pendingFilters[filterName].value}%</div>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className={`volume-controls ${disabled ? 'disabled' : ''}`}>
  <div className="volume-sliders">
  {['narration', 'music', 'overall'].map((type) => {
  const currentValue = volume[type] || 0;
  
  return (
    <div key={type} className="slider-container">
      <label>{type.charAt(0).toUpperCase() + type.slice(1)}</label>
      <div className="slider-wrapper">
        <input
          id={`${type}-slider`}
          type="range"
          className="slider"
          min="0"
          max="100"
          value={currentValue}
          onChange={(e) => {
            const newValue = Number(e.target.value);
            handleVolumeChange(type, newValue);
          }}
          disabled={disabled}
          style={{
            background: `linear-gradient(to right, #a020f0 0%, #a020f0 ${currentValue}%, #444 ${currentValue}%, #444 100%)`
          }}
        />
        <span className="slider-value">{currentValue}%</span> {/* Changed to span */}
      </div>
    </div>
  );
})}
  </div>

      <div className="filters-grid">
        {Object.entries(pendingFilters).map(([filterName, filterData]) => (
          <div
            key={filterName}
            className={`filter-wrapper ${expandedFilter === filterName ? 'expanded' : ''}`}
          >
            <div className="filter-header" onClick={() => toggleFilterExpansion(filterName)}>
              <label className="neomorphic-checkbox-label">
                <input
                  type="checkbox"
                  className="neomorphic-checkbox"
                  checked={filterData.enabled || false}
                  onChange={() => handleCheckboxToggle(filterName)}
                  disabled={disabled}
                />
                <span className="checkbox-label-text">
                  {filterName.replace(/([A-Z])/g, ' $1').trim()}
                </span>
              </label>
              {expandedFilter === filterName ? (
                <IoChevronUpSharp className="chevron" size={20} />
              ) : (
                <IoChevronDownSharp className="chevron" size={20} />
              )}
            </div>
            {expandedFilter === filterName && renderFilterControls(filterName, filterData)}
          </div>
        ))}
      </div>

      <div className={`filters-apply-button-container ${hasChanges ? 'visible' : ''}`}>
        <button
          className="filters-apply-button"
          onClick={applyFilters}
          disabled={disabled || !hasChanges}
        >
          Apply Changes
        </button>
      </div>
    </div>
  );
};

export default VolumeControls;
