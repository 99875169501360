import React from 'react';
import { FaDownload, FaShare } from 'react-icons/fa';
import './ExportButton.css';

const ExportButton = ({ mixedAudioUrl }) => {
  const handleDownload = () => {
    if (mixedAudioUrl) {
      // Ensure the full URL is being used
      const fullUrl = mixedAudioUrl.startsWith('http') ? mixedAudioUrl : `http://localhost:5000${mixedAudioUrl}`;
      
      console.log('Attempting to download from URL:', fullUrl);

      fetch(fullUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.blob();
        })
        .then(blob => {
          console.log('Blob received:', blob);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;

          // Generate filename with timestamp
          const timestamp = new Date().toISOString().replace(/[:.]/g, '-'); // Replace special characters
          a.download = `my_meditation_${timestamp}.wav`;

          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
          console.log('Download initiated');
        })
        .catch(error => {
          console.error('Error downloading the file:', error);
          alert('Failed to download the file. Please try again.');
        });
    } else {
      console.error('No mixedAudioUrl provided');
      alert('No audio file available for download.');
    }
  };

  const handleShare = async () => {
    if (!mixedAudioUrl) {
      console.error('No mixedAudioUrl provided');
      alert('No audio file available for sharing.');
      return;
    }

    const fullUrl = mixedAudioUrl.startsWith('http') ? mixedAudioUrl : `http://localhost:5000${mixedAudioUrl}`;

    try {
      // Shorten the URL
      const shortenedUrl = await getShortenedUrl(fullUrl);

      if (navigator.share) {
        navigator.share({
          title: 'My Meditation Audio',
          text: 'Check out this meditation audio I created!',
          url: shortenedUrl,
        })
          .then(() => console.log('Successful share'))
          .catch((error) => {
            console.log('Error sharing', error);
            alert('Failed to share. The shortened link has been copied to your clipboard instead.');
            copyToClipboard(shortenedUrl);
          });
      } else {
        copyToClipboard(shortenedUrl);
        alert('Shortened link copied to clipboard!');
      }
    } catch (error) {
      console.error('Error generating shortened URL:', error);
      alert('Failed to shorten the URL. Please try again.');
    }
  };

  const getShortenedUrl = async (longUrl) => {
    const bitlyApiUrl = 'https://api-ssl.bitly.com/v4/shorten';
    const bitlyAccessToken = '067d57c5819ca4b64c413d5c1da6d250f6e9c63f';  // Replace with your Bitly access token

    const response = await fetch(bitlyApiUrl, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${bitlyAccessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        long_url: longUrl,
        domain: 'bit.ly'
      })
    });

    if (!response.ok) {
      throw new Error('Failed to shorten the URL');
    }

    const data = await response.json();
    return data.link;  // The shortened URL
  };

  const copyToClipboard = (text) => {
    const tempInput = document.createElement('input');
    document.body.appendChild(tempInput);
    tempInput.value = text;
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
  };

  return (
    <div className="export-button-container">
      <button className="export-button" onClick={handleDownload} disabled={!mixedAudioUrl}>
        <FaDownload /> Download
      </button>
      <button className="export-button" onClick={handleShare} disabled={!mixedAudioUrl}>
        <FaShare /> Share
      </button>
    </div>
  );
};

export default ExportButton;
