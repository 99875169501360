import React, { useState, useEffect } from 'react';
import './DecisionTree.css';
import { FiRefreshCw } from 'react-icons/fi';

const DecisionTree = ({
  onScriptGenerated,
  customPrompt,
  setCustomPrompt,
  setMeditationScript,
  onRecordingComplete,
  isScriptLoading,
  setIsScriptLoading,
  PushToSpeakButton,
  isPersonalizationMode,
  setIsPersonalizationMode,
  selectedOptions,
  setSelectedOptions,
  setShowDecisionTree,
  isScriptGenerated,
  setIsScriptGenerated,  // Add this
  setEditorMode,
  setErrorMessage,
  cleanText,
  LoadingController,
  selectedVoice
}) => {

  const [currentNode, setCurrentNode] = useState(null);
  const [expandedNodes, setExpandedNodes] = useState([]);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    setCurrentNode(decisionTree.root);
    setExpandedNodes([decisionTree.root]);
  }, []);

  useEffect(() => {
    console.log("isScriptGenerated has been set to:", isScriptGenerated);
  }, [isScriptGenerated]);

  const handlePersonalizedOption = () => {
    setIsPersonalizationMode(true);
    setCustomPrompt((prevPrompt) => `${prevPrompt}\nRecord or type to personalize your meditation:`);
    setShowDecisionTree(false);
  };

  const handleGenerateScript = async (options, personalizationNote = '') => {
    if (isScriptLoading) return;
  
    setIsScriptLoading(true);
    LoadingController.start();
  
    const selectedDurationOption = options.find(option => option.wordCount);
    const wordCount = selectedDurationOption ? selectedDurationOption.wordCount : 1500;
  
    try {
      const response = await fetch(`${baseUrl}/generate-ssml`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          prompt: customPrompt,
          selected_options: options.map((option) => option.label),
          word_count: wordCount,
          personalization: personalizationNote,
          transcription: '',
        }),
      });
  
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
  
      const data = await response.json();
      if (data.error) {
        console.error('Error generating SSML:', data.error);
      } else {
        if (typeof setMeditationScript === 'function') {
          // Clean the text first
          const cleanedScript = cleanText(data.ssml_script);
          
          // Wait for text cleaning to complete
          await new Promise(resolve => setTimeout(resolve, 100));
  
          // Set all states after text is cleaned
          setMeditationScript(cleanedScript);
          onScriptGenerated(cleanedScript, options);
          setEditorMode('script');
          setIsScriptGenerated(true);
        } else {
          console.warn('setMeditationScript is not a function');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage(error.message);
    } finally {
      setTimeout(() => {
        setIsScriptLoading(false);
        setIsPersonalizationMode(false);
        setShowDecisionTree(false);
      }, LoadingController.getRemainingTime());
    }
  };



  const handleOptionSelect = (option) => {
    if (!selectedOptions.some((opt) => opt.label === option.label)) {
      setSelectedOptions((prevOptions) => [...prevOptions, { label: option.label, className: option.className }]);
    }
  
    if (option.label === 'Personalized') {
      handlePersonalizedOption();
    } else if (option.label === 'Guided') {
      handleGenerateScript(selectedOptions);
      setShowDecisionTree(false);
    } else if (typeof option.nextNode === 'function') {
      option.nextNode(selectedOptions);
    } else {
      const nextNode = decisionTree[option.nextNode];
      if (nextNode) {
        setCurrentNode(nextNode);
        setExpandedNodes([nextNode]);
      } else {
        handleGenerateScript(selectedOptions);
        setShowDecisionTree(false);
      }
    }
  };

  const handleManualScriptGeneration = () => {
    handleGenerateScript(selectedOptions, customPrompt);
  };

  const resetDecisionTree = () => {
    setCurrentNode(decisionTree.root);
    setSelectedOptions([]);
    setExpandedNodes([decisionTree.root]);
  
    if (typeof setMeditationScript === 'function') {
      setMeditationScript('');
    }
  
    setIsPersonalizationMode(false);
    setCustomPrompt('');
  };

  const toggleNode = (node) => {
    setExpandedNodes((prevNodes) => (prevNodes.includes(node) ? [] : [node]));
  };

  const renderNode = (node) => {
    const isExpanded = expandedNodes.includes(node);

    return (
      <div className={`decision-tree-node ${isExpanded ? 'expanded' : ''}`} onClick={() => toggleNode(node)}>
        <div>{node.prompt}</div>
        {isExpanded && (
          <div className="decision-tree-options">
            {node.options?.map((option, index) => (
              <button
                key={index}
                className={`decision-tree-option ${option.className}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOptionSelect(option);
                }}
              >
                {option.label}
              </button>
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderPersonalizationStep = () => (
    <div className="personalization-step">
      <h3>Add Your Personalization</h3>
      <p>Use the Push to Speak button to record your personalization, or type it in the text box below.</p>
      <textarea
        value={customPrompt}
        onChange={(e) => setCustomPrompt(e.target.value)}
        placeholder="Type your personalization here..."
      />
      <div className="personalization-buttons">
        <button className="wave-btn" onClick={handleManualScriptGeneration}>
          <span className="wave-effect" />
          <span className="wave-text">Generate Script</span>
        </button>
      </div>
    </div>
  );

const decisionTree = {
  root: {
    prompt: 'What kind of meditation would you like to try today?',
    options: [
      { label: 'Visualization', nextNode: 'visualization', className: 'visualization-main', isMainCategory: true },
      { label: 'Mindfulness', nextNode: 'mindfulness', className: 'mindfulness-main', isMainCategory: true },
      { label: 'Sleep', nextNode: 'sleep', className: 'sleep-main', isMainCategory: true },
      { label: 'Manifestation', nextNode: 'manifestation', className: 'manifestation-main', isMainCategory: true },
      { label: 'Breath', nextNode: 'breath_awareness', className: 'breath-main', isMainCategory: true },
      { label: 'Affirmations', nextNode: 'affirmations', className: 'affirmations-main', isMainCategory: true },
      { label: 'Concentration', nextNode: 'concentration', className: 'concentration-main', isMainCategory: true },
      { label: 'Body Scan', nextNode: 'body_scan', className: 'body_scan-main', isMainCategory: true },
      { label: 'Unguided', nextNode: 'unguided', className: 'unguided-main', isMainCategory: true },
    ],
  },
  visualization: {
    prompt: 'What kind of visualization would you like to focus on?',
    options: [
      { label: 'Nature Scenes', nextNode: 'visualizationDuration', className: 'visualization-sub-1' },
      { label: 'Peaceful Places', nextNode: 'visualizationDuration', className: 'visualization-sub-1' },
      { label: 'Specific Goals', nextNode: 'visualizationDuration', className: 'visualization-sub-1' },
    ],
  },
  visualizationDuration: {
    prompt: 'How long would you like your visualization meditation to be?',
    options: [
      { label: '5 minutes', nextNode: 'visualizationNotes', className: 'visualization-sub-2', wordCount: 750 },
      { label: '10 minutes', nextNode: 'visualizationNotes', className: 'visualization-sub-2', wordCount: 1500 },
      { label: '20 minutes', nextNode: 'visualizationNotes', className: 'visualization-sub-2', wordCount: 3000 },
    ],
  },
  visualizationNotes: {
    prompt: 'Would you like to add customization to your visualization meditation?',
    options: [
      { label: 'Personalized', nextNode: null, className: 'visualization-result' }, // nextNode is null
      { label: 'Guided', nextNode: null, className: 'visualization-result' }, // nextNode is null
    ],
  },
  mindfulness: {
    prompt: 'What kind of mindfulness practice would you prefer?',
    options: [
      { label: 'Body Scan', nextNode: 'mindfulnessDuration', className: 'mindfulness-sub-1' },
      { label: 'Breath Focus', nextNode: 'mindfulnessDuration', className: 'mindfulness-sub-1' },
      { label: 'Open Awareness', nextNode: 'mindfulnessDuration', className: 'mindfulness-sub-1' },
    ],
  },
  mindfulnessDuration: {
    prompt: 'How long would you like your mindfulness meditation to be?',
    options: [
      { label: '5 minutes', nextNode: 'mindfulnessNotes', className: 'mindfulness-sub-2', wordCount: 750 },
      { label: '10 minutes', nextNode: 'mindfulnessNotes', className: 'mindfulness-sub-2', wordCount: 1500 },
      { label: '20 minutes', nextNode: 'mindfulnessNotes', className: 'mindfulness-sub-2', wordCount: 3000 },
    ],
  },
  mindfulnessNotes: {
    prompt: 'Would you like to add customization to your mindfulness meditation?',
    options: [
      { label: 'Personalized', nextNode: null, className: 'mindfulness-result' }, // nextNode is null
      { label: 'Guided', nextNode: null, className: 'mindfulness-result' }, // nextNode is null
    ],
  },
  sleep: {
    prompt: 'What would you like to focus on for better sleep?',
    options: [
      { label: 'Progressive Relaxation', nextNode: 'sleepDuration', className: 'sleep-sub-1' },
      { label: 'Soothing Bedtime Story', nextNode: 'sleepDuration', className: 'sleep-sub-1' },
      { label: 'Calming Breathing', nextNode: 'sleepDuration', className: 'sleep-sub-1' },
    ],
  },
  sleepDuration: {
    prompt: 'How long would you like your sleep meditation to be?',
    options: [
      { label: '5 minutes', nextNode: 'sleepNotes', className: 'sleep-sub-2', wordCount: 750 },
      { label: '10 minutes', nextNode: 'sleepNotes', className: 'sleep-sub-2', wordCount: 1500 },
      { label: '20 minutes', nextNode: 'sleepNotes', className: 'sleep-sub-2', wordCount: 3000 },
    ],
  },
  sleepNotes: {
    prompt: 'Would you like to add customization to your sleep meditation?',
    options: [
      { label: 'Personalized', nextNode: null, className: 'sleep-result' }, // nextNode is null
      { label: 'Guided', nextNode: null, className: 'sleep-result' }, // nextNode is null
    ],
  },
  manifestation: {
    prompt: 'What are you trying to manifest?',
    options: [
      { label: 'Abundance', nextNode: 'manifestationDuration', className: 'manifestation-sub-1' },
      { label: 'Love', nextNode: 'manifestationDuration', className: 'manifestation-sub-1' },
      { label: 'Health', nextNode: 'manifestationDuration', className: 'manifestation-sub-1' },
    ],
  },
  manifestationDuration: {
    prompt: 'How long would you like your manifestation meditation to be?',
    options: [
      { label: '5 minutes', nextNode: 'manifestationNotes', className: 'manifestation-sub-2', wordCount: 750 },
      { label: '10 minutes', nextNode: 'manifestationNotes', className: 'manifestation-sub-2', wordCount: 1500 },
      { label: '20 minutes', nextNode: 'manifestationNotes', className: 'manifestation-sub-2', wordCount: 3000 },
    ],
  },
  manifestationNotes: {
    prompt: 'Would you like to add customization to your manifestation meditation?',
    options: [
      { label: 'Personalized', nextNode: null, className: 'manifestation-result' }, // nextNode is null
      { label: 'Guided', nextNode: null, className: 'manifestation-result' }, // nextNode is null
    ],
  },
  breath_awareness: {
    prompt: 'What kind of breath awareness practice would you like?',
    options: [
      { label: 'Breath Counting', nextNode: 'breathAwarenessDuration', className: 'breath-sub-1' },
      { label: 'Breath Sensations', nextNode: 'breathAwarenessDuration', className: 'breath-sub-1' },
      { label: 'Rhythmic Breathing', nextNode: 'breathAwarenessDuration', className: 'breath-sub-1' },
    ],
  },
  breathAwarenessDuration: {
    prompt: 'How long would you like your breath awareness meditation to be?',
    options: [
      { label: '5 minutes', nextNode: 'breathAwarenessNotes', className: 'breath-sub-2', wordCount: 750 },
      { label: '10 minutes', nextNode: 'breathAwarenessNotes', className: 'breath-sub-2', wordCount: 1500 },
      { label: '20 minutes', nextNode: 'breathAwarenessNotes', className: 'breath-sub-2', wordCount: 3000 },
    ],
  },
  breathAwarenessNotes: {
    prompt: 'Would you like to add customization to your breath awareness meditation?',
    options: [
      { label: 'Personalized', nextNode: null, className: 'breath-result' }, // nextNode is null
      { label: 'Guided', nextNode: null, className: 'breath-result' }, // nextNode is null
    ],
  },
  affirmations: {
    prompt: 'What type of affirmations would you like to focus on?',
    options: [
      { label: 'Self-Love', nextNode: 'affirmationsDuration', className: 'affirmations-sub-1' },
      { label: 'Confidence', nextNode: 'affirmationsDuration', className: 'affirmations-sub-1' },
      { label: 'Gratitude', nextNode: 'affirmationsDuration', className: 'affirmations-sub-1' },
    ],
  },
  affirmationsDuration: {
    prompt: 'How long would you like your affirmations meditation to be?',
    options: [
      { label: '5 minutes', nextNode: 'affirmationsNotes', className: 'affirmations-sub-2', wordCount: 750 },
      { label: '10 minutes', nextNode: 'affirmationsNotes', className: 'affirmations-sub-2', wordCount: 1500 },
      { label: '20 minutes', nextNode: 'affirmationsNotes', className: 'affirmations-sub-2', wordCount: 3000 },
    ],
  },
  affirmationsNotes: {
    prompt: 'Would you like to add customization to your affirmations meditation?',
    options: [
      { label: 'Personalized', nextNode: null, className: 'affirmations-result' }, // nextNode is null
      { label: 'Guided', nextNode: null, className: 'affirmations-result' }, // nextNode is null
    ],
  },
  concentration: {
    prompt: 'What would you like to focus your concentration on?',
    options: [
      { label: 'Candle Flame', nextNode: 'concentrationDuration', className: 'concentration-sub-1' },
      { label: 'Visual Object', nextNode: 'concentrationDuration', className: 'concentration-sub-1' },
      { label: 'Mantra', nextNode: 'concentrationDuration', className: 'concentration-sub-1' },
    ],
  },
  concentrationDuration: {
    prompt: 'How long would you like your concentration meditation to be?',
    options: [
      { label: '5 minutes', nextNode: 'concentrationNotes', className: 'concentration-sub-2', wordCount: 750 },
      { label: '10 minutes', nextNode: 'concentrationNotes', className: 'concentration-sub-2', wordCount: 1500 },
      { label: '20 minutes', nextNode: 'concentrationNotes', className: 'concentration-sub-2', wordCount: 3000 },
    ],
  },
  concentrationNotes: {
    prompt: 'Would you like to add customization to your concentration meditation?',
    options: [
      { label: 'Personalized', nextNode: null, className: 'concentration-result' }, // nextNode is null
      { label: 'Guided', nextNode: null, className: 'concentration-result' }, // nextNode is null
    ],
  },
  body_scan: {
    prompt: 'What kind of body scan would you like to practice?',
    options: [
      { label: 'Detailed', nextNode: 'bodyScanDuration', className: 'body_scan-sub-1' },
      { label: 'General', nextNode: 'bodyScanDuration', className: 'body_scan-sub-1' },
    ],
  },
  bodyScanDuration: {
    prompt: 'How long would you like your body scan meditation to be?',
    options: [
      { label: '5 minutes', nextNode: 'bodyScanNotes', className: 'body_scan-sub-2', wordCount: 750 },
      { label: '10 minutes', nextNode: 'bodyScanNotes', className: 'body_scan-sub-2', wordCount: 1500 },
      { label: '20 minutes', nextNode: 'bodyScanNotes', className: 'body_scan-sub-2', wordCount: 3000 },
    ],
  },
  bodyScanNotes: {
    prompt: 'Would you like to add customization to your body scan meditation?',
    options: [
      { label: 'Personalized', nextNode: null, className: 'body_scan-result' }, // nextNode is null
      { label: 'Guided', nextNode: null, className: 'body_scan-result' }, // nextNode is null
    ],
  },
  unguided: {
    prompt: 'Would you like to proceed with an unguided meditation session?',
    options: [
      { label: 'Yes', nextNode: null, className: 'unguided-result' }, // nextNode is null
      { label: 'No', nextNode: 'root', className: 'unguided-main' },
    ],
  },
};

return (
  <div className="decision-tree-container">
    <div className="icon-group">
      <div className="icon-container reset-icon" onClick={resetDecisionTree}>
        <FiRefreshCw />
      </div>
      <div className="icon-container close-icon" onClick={() => setShowDecisionTree(false)}>
        &times;
      </div>
    </div>

    {isPersonalizationMode ? (
      renderPersonalizationStep()
    ) : currentNode ? (
      <>
        {renderNode(currentNode)}
        <div className="selected-options-container">
          {selectedOptions.map((option, index) => (
            <span key={index} className={`selected-option ${option.className}`}>
              {option.label}
            </span>
          ))}
        </div>
      </>
    ) : (
      <div className="completed-message">
        {isScriptLoading ? 'Generating your meditation script...' : 'Your meditation script is ready!'}
      </div>
    )}
    {PushToSpeakButton && isPersonalizationMode && (
      <PushToSpeakButton
        onRecordingComplete={onRecordingComplete}
        isRecording={false}
        onRecordingToggle={() => {}}
        setIsRecordingAnimation={() => {}}
      />
    )}
  </div>
);
};

export default DecisionTree;