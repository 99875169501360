import React, { useState, useRef, useEffect, useCallback } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { BsStopFill, BsPlayFill, BsPauseFill, BsSkipForward, BsSkipBackward } from 'react-icons/bs';
import './AudioPlayer.css';

const AudioPlayer = ({ initialAudioUrl, volume = 1, onError, onPlayingStateChange, onAudioLoaded }) => {
  // State
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlayerReady, setIsPlayerReady] = useState(false);

  // Refs
  const waveformRef = useRef(null);
  const waveSurferRef = useRef(null);
  const isMounted = useRef(true);
  const cursorRef = useRef(null);
  const scrubberRef = useRef(null);
  const scrubberHandleRef = useRef(null);
  const isCreatingInstanceRef = useRef(false);

  // Cleanup function
  const cleanupWaveSurfer = useCallback(() => {
    console.log('Cleaning up WaveSurfer instance');
    
    if (waveSurferRef.current) {
      try {
        waveSurferRef.current.unAll();
        waveSurferRef.current.destroy();
      } catch (error) {
        console.error('Error destroying WaveSurfer:', error);
      }
      waveSurferRef.current = null;
    }

    isCreatingInstanceRef.current = false;
    setIsPlayerReady(false);
  }, []);

  // Format time helper
  const formatTime = useCallback((time) => {
    if (!isFinite(time)) return '0:00';
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  }, []);

  // Update cursor position with scroll handling
  const updateCursorPosition = useCallback(() => {
    if (!waveSurferRef.current || !cursorRef.current || !scrubberHandleRef.current || !scrubberRef.current || !waveformRef.current) {
      return;
    }

    requestAnimationFrame(() => {
      if (!isMounted.current) return;

      const currentTime = waveSurferRef.current.getCurrentTime();
      const duration = waveSurferRef.current.getDuration();
      
      if (!isFinite(currentTime) || !isFinite(duration) || duration === 0) return;

      const progress = currentTime / duration;
      const waveformWidth = waveformRef.current.scrollWidth;
      const scrubberWidth = scrubberRef.current.clientWidth;
      const containerWidth = waveformRef.current.clientWidth;

      // Update cursor position
      const cursorPosition = (progress * waveformWidth) - waveformRef.current.scrollLeft;
      cursorRef.current.style.left = `${cursorPosition}px`;
      cursorRef.current.style.transform = 'translateX(-50%)';

      // Update scrubber position
      scrubberHandleRef.current.style.left = `${progress * scrubberWidth}px`;

      // Center the playhead
      const targetScroll = (progress * waveformWidth) - (containerWidth / 2);
      if (Math.abs(waveformRef.current.scrollLeft - targetScroll) > 1) {
        waveformRef.current.scrollLeft = targetScroll;
      }
    });
  }, []);

  // Create WaveSurfer instance
  const createWaveSurferInstance = useCallback(() => {
    if (!initialAudioUrl || !waveformRef.current || isCreatingInstanceRef.current) {
      console.log('Skipping WaveSurfer creation:', {
        hasUrl: !!initialAudioUrl,
        hasWaveformRef: !!waveformRef.current,
        isCreating: isCreatingInstanceRef.current
      });
      return;
    }

    console.log('Creating new WaveSurfer instance for URL:', initialAudioUrl);
    isCreatingInstanceRef.current = true;
    cleanupWaveSurfer();
    setIsLoading(true);
    setError(null);

    try {
      const waveSurferInstance = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: '#1e2023',
        progressColor: 'rgba(125, 18, 255, 1)',
        cursorColor: 'transparent',
        barWidth: 1,
        barGap: 2,
        responsive: true,
        height: 80,
        backend: 'WebAudio',
        normalize: true,
        hideScrollbar: true,
        interact: true,
        minPxPerSec: 50,
        fillParent: false,
        scrollParent: true,
        autoCenter: true,
        pixelRatio: 1
      });

      waveSurferRef.current = waveSurferInstance;

      // Event handlers
      waveSurferInstance.on('ready', () => {
        if (!isMounted.current) return;
        
        console.log('WaveSurfer ready event fired');
        setIsLoading(false);
        setIsPlayerReady(true);
        
        const audioDuration = waveSurferInstance.getDuration();
        setDuration(isFinite(audioDuration) ? audioDuration : 0);
        
        waveSurferInstance.setVolume(volume);
        waveSurferInstance.zoom(50);
        updateCursorPosition();
        isCreatingInstanceRef.current = false;
        onAudioLoaded?.();
      });

      waveSurferInstance.on('audioprocess', () => {
        if (!isMounted.current || !waveSurferInstance) return;
        
        const currentTime = waveSurferInstance.getCurrentTime();
        if (isFinite(currentTime)) {
          setCurrentTime(currentTime);
          updateCursorPosition();
        }
      });

      waveSurferInstance.on('seek', () => {
        if (!isMounted.current || !waveSurferInstance) return;
        
        const currentTime = waveSurferInstance.getCurrentTime();
        if (isFinite(currentTime)) {
          setCurrentTime(currentTime);
          updateCursorPosition();
        }
      });

      waveSurferInstance.on('error', (err) => {
        console.error('WaveSurfer error:', err);
        if (!isMounted.current) return;

        setError('Error loading audio');
        setIsLoading(false);
        isCreatingInstanceRef.current = false;
        onError?.(err);
      });

      waveSurferInstance.on('finish', () => {
        if (!isMounted.current) return;
        
        setIsPlaying(false);
        onPlayingStateChange?.(false);
        updateCursorPosition();
      });

      console.log('Loading audio URL:', initialAudioUrl);
      waveSurferInstance.load(initialAudioUrl);

    } catch (error) {
      console.error('Error creating WaveSurfer instance:', error);
      setError('Failed to initialize audio player');
      setIsLoading(false);
      isCreatingInstanceRef.current = false;
      onError?.(error);
    }
  }, [initialAudioUrl, volume, onError, cleanupWaveSurfer, updateCursorPosition, onAudioLoaded, onPlayingStateChange]);

// Effects
useEffect(() => {
  if (!initialAudioUrl) return;

  console.log('Audio URL changed, initializing player');
  cleanupWaveSurfer();
  setIsLoading(true);
  setCurrentTime(0);
  setDuration(0);
  setIsPlaying(false);
  onPlayingStateChange?.(false);

  // Wait for next frame to ensure DOM is ready
  requestAnimationFrame(() => {
    if (isMounted.current && waveformRef.current) {
      createWaveSurferInstance();
    }
  });
}, [initialAudioUrl, createWaveSurferInstance, cleanupWaveSurfer, onPlayingStateChange]);

// Handle volume changes
useEffect(() => {
  if (waveSurferRef.current && isPlayerReady) {
    waveSurferRef.current.setVolume(volume);
  }
}, [volume, isPlayerReady]);

// Cleanup on unmount
useEffect(() => {
  return () => {
    console.log('AudioPlayer unmounting');
    isMounted.current = false;
    cleanupWaveSurfer();
  };
}, [cleanupWaveSurfer]);

// Playback controls
const handlePlayPause = useCallback(() => {
  if (!waveSurferRef.current || !isPlayerReady || isLoading) return;

  setIsPlaying(prev => {
    const newState = !prev;
    waveSurferRef.current[newState ? 'play' : 'pause']();
    onPlayingStateChange?.(newState);
    return newState;
  });
}, [isPlayerReady, isLoading, onPlayingStateChange]);

const handleStop = useCallback(() => {
  if (!waveSurferRef.current || !isPlayerReady) return;

  waveSurferRef.current.stop();
  setIsPlaying(false);
  onPlayingStateChange?.(false);
  setCurrentTime(0);
  updateCursorPosition();
}, [isPlayerReady, updateCursorPosition, onPlayingStateChange]);

const handleSeek = useCallback((direction) => {
  if (!waveSurferRef.current || !isPlayerReady) return;

  const currentTime = waveSurferRef.current.getCurrentTime();
  const duration = waveSurferRef.current.getDuration();
  const newTime = Math.max(0, Math.min(duration, currentTime + direction * 5));
  waveSurferRef.current.seekTo(newTime / duration);
}, [isPlayerReady]);

const handleScrubberClick = useCallback((e) => {
  if (!waveSurferRef.current || !scrubberRef.current || !isPlayerReady) return;

  const rect = scrubberRef.current.getBoundingClientRect();
  const x = e.clientX - rect.left;
  const progress = Math.max(0, Math.min(1, x / rect.width));
  waveSurferRef.current.seekTo(progress);
}, [isPlayerReady]);

return (
  <div className="audio-player-container">
    {error && <div className="error-message">{error}</div>}
    
    <div className="waveform-loading-container">
      <div className="waveform-container">
        <div ref={waveformRef} className="waveform"></div>
        <div ref={cursorRef} className="custom-cursor"></div>
      </div>
      {isLoading && (
        <div className="loading-indicator">
          <div className="spinner" />
          <span>Loading audio...</span>
        </div>
      )}
    </div>

    <div className="scrubber-wrapper">
      <span className="current-time">{formatTime(currentTime)}</span>
      <div 
        className="scrubber-container" 
        onClick={handleScrubberClick} 
        ref={scrubberRef}
      >
        <div className="scrubber-track"></div>
        <div ref={scrubberHandleRef} className="scrubber-handle"></div>
      </div>
      <span className="total-duration">{formatTime(duration)}</span>
    </div>

    <div className="wavesurfer-controls">
      <button
        className="control-btn skip-btn"
        onClick={() => handleSeek(-5)}
        disabled={isLoading || !isPlayerReady}
      >
        <BsSkipBackward size={24} />
      </button>
      <button
        className={`control-btn ${isPlaying ? 'playing' : ''}`}
        onClick={handlePlayPause}
        disabled={isLoading || !isPlayerReady}
      >
        {isPlaying ? <BsPauseFill size={24} /> : <BsPlayFill size={24} />}
      </button>
      <button 
        className="control-btn stop-btn" 
        onClick={handleStop} 
        disabled={isLoading || !isPlayerReady}
      >
        <BsStopFill size={24} />
      </button>
      <button
        className="control-btn skip-btn"
        onClick={() => handleSeek(5)}
        disabled={isLoading || !isPlayerReady}
      >
        <BsSkipForward size={24} />
      </button>
    </div>
  </div>
);
};

export default AudioPlayer;